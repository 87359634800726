import React, { Component } from 'react';

export default class ButtonHero extends Component {

  render() {
    return(
      <p>Click on an emoji to view the emoji short name.</p>
    )
  }

}